/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "./theme/custom.theme.scss";

:root {
	--ion-color-primary: #1D3C77;
	--ion-color-primary-rgb: 29,60,119;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #1a3569;
	--ion-color-primary-tint: #345085;
}

.ion-color-primary {
	--ion-color-base: var(--ion-color-primary);
	--ion-color-base-rgb: var(--ion-color-primary-rgb);
	--ion-color-contrast: var(--ion-color-primary-contrast);
	--ion-color-contrast-rgb: var(--ion-color-primary-contrast-rgb);
	--ion-color-shade: var(--ion-color-primary-shade);
	--ion-color-tint: var(--ion-color-primary-tint);
}

:root {
	--ion-color-secondary: #00A6EB;
	--ion-color-secondary-rgb: 0,166,235;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #0092cf;
	--ion-color-secondary-tint: #1aafed;
}

.ion-color-secondary {
	--ion-color-base: var(--ion-color-secondary); 
	--ion-color-base-rgb: var(--ion-color-secondary-rgb);
	--ion-color-contrast: var(--ion-color-secondary-contrast);
	--ion-color-contrast-rgb: var(--ion-color-secondary-contrast-rgb);
	--ion-color-shade: var(--ion-color-secondary-shade);
	--ion-color-tint: var(--ion-color-secondary-tint);
}

:root {
	--ion-color-transparent: #00000000;
	--ion-color-transparent-rgb: 0,0,0;
	--ion-color-transparent-contrast: #ffffff;
	--ion-color-transparent-contrast-rgb: 255,255,255;
	--ion-color-transparent-shade: #000000;
	--ion-color-transparent-tint: #1a1a1a;
}

.ion-color-transparent {
	--ion-color-base: var(--ion-color-transparent);
	--ion-color-base-rgb: var(--ion-color-transparent-rgb);
	--ion-color-contrast: var(--ion-color-transparent-contrast);
	--ion-color-contrast-rgb: var(--ion-color-transparent-contrast-rgb);
	--ion-color-shade: var(--ion-color-transparent-shade);
	--ion-color-tint: var(--ion-color-transparent-tint);
}

.custom-align-toolbar {
//  text-align: center !important;
}

.custom-padding-error {
  margin: auto !important;
  color: red;
  text-align: center;
  padding-top: 2%;
}

.font-size-xx-large {
  font-size: xx-large !important;
}

.font-size-x-large {
  font-size: x-large !important;
}

.font-size-large {
  font-size: large !important;
}

.font-size-medium {
  font-size: medium !important;
}

.font-size-small {
  font-size: small !important;
}

.font-size-x-small {
  font-size: x-small !important;
}

.font-size-xx-small {
  font-size: xx-small !important;
}

.text-transform-none {
  text-transform: none;
}

.font-size-12-rem {
  font-size: 1.2rem;
}

.font-size-15-rem {
  font-size: 1.5rem;
}

.font-size-20-rem {
  font-size: 2rem;
}

.font-oblique {
  font-style: oblique;
}

.z-index-back {
  z-index: 1;
}

.z-index-10 {
  z-index: 10;
}

.z-index-100 {
  z-index: 100;
}

.z-index-1000 {
  z-index: 1000;
}

.z-index-10000 {
  z-index: 10000;
}

.z-index-top {
  z-index: 99999;
}

.custom-padding-1 {
  padding-top: 2%;
}

.custom-margin-1 {
  margin-top: 2% !important;
}

.custom-logo-login {
  width: 8rem;
  margin: auto;
}

.custom-margin-top-selector {
  margin-top: 5rem;
}

.my-modal-class {
  .modal-wrapper {
    height: 45%;
    width: 90%;
    border-radius: 10px;
  }
}

.my-modal-class-rejection {
  .modal-wrapper {
    height: 65%;
    width: 90%;
    border-radius: 10px;
  }
}

.my-modal-class-specialty {
  .modal-wrapper {
    height: 80%;
    width: 90%;
    border-radius: 10px;
  }
}

.my-modal-qr {
  .modal-wrapper {
    height: 40%;
    width: 90%;
    border-radius: 10px;
  }
}

.custom-arrow-back {
  background: transparent !important;
  font-size: 1.5rem;
}

.qrClass {
  img {
      width: 15rem !important;
      margin: auto;
  }
}

.modal_medic_patient{
  .modal-wrapper {
    height: 100%;
    width: 100%;
  }
}